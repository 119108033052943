import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import cx from './Message.module.css';

import { Copy, GptzatorAvatar } from '../../../../lib/getIcon';
import { UserOutlined } from '@ant-design/icons';
import { TMessage, TMessageChoicesItem } from '../../model/types';
import { Annotations } from '../Annotations';
import { removeBraces } from 'shared/lib/removeBraces';
import { mdToHtml } from 'shared/lib/mdToHtml';
import { StapleIcon, PaperClipOutlined } from 'shared/ui/Icons';

export const Message = memo((props: { message: TMessage; handleImgClick?: (imgSrc: string) => void }) => {
	const [textContents, setTextContents] = useState<Record<string, string>>({});
	const { message, handleImgClick } = props;
	const { userAvatar, type, choices } = message;
	useEffect(() => {
		const createHtmlStr = async (contentItem: TMessageChoicesItem) => {
			try {
				const htmlStr = await mdToHtml(
					contentItem.annotations
						? renderMessageWithAnnotations(contentItem.content, contentItem.annotations)
						: contentItem.content
				);
				setTextContents((prev) => ({ ...prev, [contentItem.id]: htmlStr }));
			} catch (e) {
				setTextContents((prev) => ({ ...prev, [contentItem.id]: contentItem.content }));
			}
		};

		Promise.all(choices.map((contentItem) => createHtmlStr(contentItem))).catch((error) => {
			console.error('create HTML error', error);
		});
	}, [choices]);

	const onCopyClick = useCallback(() => {
		const textForCopy = choices
			.map((c) => (typeof c.content === 'object' ? JSON.stringify(c.content) : c.content.toString()))
			.join(' ');
		navigator.clipboard.writeText(textForCopy);
	}, [choices]);

	const onImgClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target && e.target instanceof HTMLImageElement && handleImgClick) {
			const srcValue = e.target.src;
			handleImgClick(srcValue);
		}
	};
	const renderContent = (contentItem: TMessageChoicesItem) => {
		const text = textContents[contentItem.id] as string;
		return (
			<div key={contentItem.id} className='max-w-full'>
				<div dangerouslySetInnerHTML={{ __html: text }} onClick={onImgClick} />
				{!!contentItem.annotations && <Annotations annotations={contentItem.annotations} />}
			</div>
		);
	};

	const isAllTextContentTransformed = choices.every((textContent) => textContents[textContent.id]);

	const renderMessageWithAnnotations = (text: string, annotations: Record<string, string>) => {
		let messageWithAnnotations: string = text;

		for (const key in annotations) {
			const annotation = removeBraces(key) as string;
			const annotationIndex = parseInt(annotation, 10);
			messageWithAnnotations = messageWithAnnotations.replace(annotation, `${annotationIndex}`);
		}
		return messageWithAnnotations;
	};

	const renderMessage = useMemo(() => {
		if (type === 'userChangeContext') {
			return (
				<div className='w-full max-w-[800px] flex relative px-4 py-3  opacity-40'>
					<StapleIcon className='text-transparent text-2xl stroke-black' />

					{choices.map((contentItem) => (
						<div className='ml-2 flex flex-col w-full pl-0' key={contentItem.id}>
							{contentItem.content}
						</div>
					))}
				</div>
			);
		}
		if (type === 'userTextFile') {
			return (
				<div className='w-full max-w-[800px] flex relative px-4 py-3  opacity-40'>
					<PaperClipOutlined className='opacity-[0.45] text-2xl text-black' />

					<div className='ml-2 flex flex-col w-full pl-0'>{message.file.originalFilename}</div>
				</div>
			);
		}

		return (
			<div className='w-full max-w-[800px] flex relative rounded-xl bg-gray-bg p-4 pr-24'>
				{type === 'gpt' ? (
					<div className='min-w-[32px] w-[32px] h-[32px] rounded-full flex items-center justify-center overflow-hidden mr-4'>
						<GptzatorAvatar />
					</div>
				) : userAvatar ? (
					<div className='min-w-[32px] w-[32px] h-[32px] rounded-full flex items-center justify-center overflow-hidden mr-4'>
						<img className='w-full rounded object-none' src={userAvatar} alt='' />
					</div>
				) : (
					<UserOutlined className='min-w-[32px] h-[32px] rounded-full border border-solid border-primary text-primary flex items-center justify-center text-3xl overflow-hidden mr-4' />
				)}
				{type === 'gpt' && isAllTextContentTransformed ? (
					<div className='flex flex-col w-full pl-0'>
						<div className='min-w-[20px] w-[20px] h-[20px] flex items-center justify-center absolute right-4 top-4'>
							<Copy className={cx.copy} onClick={() => onCopyClick()} />
						</div>
						{choices.map((contentItem) => renderContent(contentItem))}
					</div>
				) : (
					<div className='flex flex-col w-full pl-0'>
						<div className='min-w-[20px] w-[20px] h-[20px] flex items-center justify-center absolute right-4 top-4'>
							<Copy className={cx.copy} onClick={() => onCopyClick()} />
						</div>
						{choices.map((contentItem) => (
							<div key={contentItem.id} className='max-w-full'>
								{contentItem.content}
							</div>
						))}
					</div>
				)}
			</div>
		);
	}, [userAvatar, type, choices, isAllTextContentTransformed]);

	return <div className={cx.root}>{renderMessage}</div>;
});
